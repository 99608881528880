import 'vue-multiselect/dist/vue-multiselect.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import VueGoogleMaps from '@fawmi/vue-google-maps';
import axios from 'axios';
import Maska from 'maska';
import mitt from 'mitt';
import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from 'vue';
import VueAxios from 'vue-axios';
import VueGtag from 'vue-gtag';
import VueMultiselect from 'vue-multiselect';
import VueVirtualScroller from 'vue-virtual-scroller';

import store from '@/store';

import App from './App.vue';
import clickOutside from './directives/click-outside';
import onlyNumbers from './directives/only-numbers';
import quasarUserOptions from './quasar-user-options';
import router from './router';
import initAxios from './services/axios.service';
import prepareLocalStorage from './utils/local-storage';

prepareLocalStorage();

// Styles import should be the last.
// It is important to replace styles from libs.
// eslint-disable-next-line simple-import-sort/imports
import './assets/main.scss';
import { MAP_API_LIBRARIES } from '@/utils/map-constants';

const emitter = mitt();
initAxios(router);
const app = createApp(App)
  .component('vue-multiselect', VueMultiselect)
  .directive('click-outside', clickOutside)
  .directive('only-numbers', onlyNumbers)
  .use(VueGtag, {
    config: { id: 'G-LEKM9YW5T8' },
  })
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(
    VueGtag,
    {
      config: { id: 'G-LEKM9YW5T8' },
    },
    router,
  )
  .use(VueAxios, axios)
  .use(Maska)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: MAP_API_LIBRARIES,
    },
  })
  .use(VueVirtualScroller);

app.provide('emitter', emitter);
app.mount('#app');
