<template>
  <div class="base-message" :class="{ 'alert-message': isAlert }">
    {{ message }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseError',
  props: {
    error: Object,
    isAlert: Boolean,
  },
  computed: {
    message() {
      // Error may be enhanced by axios.
      return this.error?.response?.data || (this.error ? String(this.error) : '');
    },
  },
});
</script>
