import type { VehicleLog } from '@/models/asset/vehicle.type';
import { VehicleFixedLocationType } from '@/models/equipment/location.type';
import type { InventoryLocation, VehicleLiteLocation } from '@/models/settings/inventory-location.model';
import { InventoryGatewayType, InventoryLocationType } from '@/models/settings/inventory-location.model';

// Global custom location id.
let inventoryLocationID = 1;

export const nextInventoryLocationID = () => {
  const id = inventoryLocationID;
  inventoryLocationID += 1;
  return id;
};

export const getInventoryLocationsId = (locations: VehicleLog[]) => locations.map(({ id }) => id).join(',');

// Service location type is a short form of service center location type.
// Server accepts only short form, but we need full form on the front end.

export const normalizeInventoryLocationType = (locationType?: InventoryLocationType) => {
  switch (locationType) {
  case InventoryLocationType.FIXED:
    return InventoryLocationType.WAREHOUSE;
  case InventoryLocationType.SERVICE:
    return InventoryLocationType.SERVICE_CENTER;
  default:
    return locationType;
  }
};

export const serializeInventoryLocationType = (locationType?: InventoryLocationType) => {
  switch (locationType) {
  case InventoryLocationType.WAREHOUSE:
    return InventoryLocationType.FIXED;
  case InventoryLocationType.SERVICE_CENTER:
    return InventoryLocationType.SERVICE;
  default:
    return locationType;
  }
};

export const normalizeInventoryLocation = (location: InventoryLocation) => {
  location.id = nextInventoryLocationID();
  location.locationType = normalizeInventoryLocationType(location.locationType);
  if (location.latitude === 0.0 && location.longitude === 0.0) {
    location.lastReadSecs = 0;
  }
  location.lastReadDate =
    location.lastReadSecs != null && location.lastReadSecs !== 0 ? new Date(location.lastReadSecs * 1000) : undefined;
  return location;
};

export const normalizeInventoryLocations = (locations: InventoryLocation[]) =>
  locations.map(normalizeInventoryLocation);

export const serializeInventoryLocation = (location: InventoryLocation) => {
  location.locationType = serializeInventoryLocationType(location.locationType);
  return location;
};

export const normalizeVehilceLocation = (locations: VehicleLiteLocation[]) => {
  locations.map((location) => {
    location.id = nextInventoryLocationID();
    return location;
  });
};

export const serializeInventoryLocations = (locations: InventoryLocation[]) =>
  locations.map(serializeInventoryLocation);

export const getNewInventoryLocation = (locationType?: InventoryLocationType) =>
  ({
    id: -nextInventoryLocationID(),
    invLocationId: undefined,
    locationType,
    name: '',
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    phoneName: undefined,
    latitude: undefined,
    longitude: undefined,
    lastReadSecs: undefined,
    gatewayType: InventoryGatewayType.STAND_ALONE,
    gatewayUuid: undefined,
    fixedLocationId: undefined,
    fixedLocationName: '',
    isGpsCurrent: false,
    status: '',
  }) as InventoryLocation;

export const getVehicleLocationName = (type?: VehicleFixedLocationType, isActive?: boolean) => {
  let name: string;

  switch (type) {
  case VehicleFixedLocationType.FIXED:
  case VehicleFixedLocationType.WAREHOUSE:
    name = 'warehouse';
    break;
  case VehicleFixedLocationType.SERVICE:
  case VehicleFixedLocationType.SERVICE_CENTER:
    name = 'service';
    break;
  case VehicleFixedLocationType.JOB:
    name = 'job';
    break;
  default:
    name = 'unnamed';
    break;
  }

  if (isActive) {
    name += '-active';
  }

  return name;
};

export const getNotVehicleLocationIcon = (type?: VehicleFixedLocationType, isActive?: boolean) => {
  const name = getVehicleLocationName(type, isActive);

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  return require(`@/assets/images/markers/${name}.svg`);
};

export const getVehicleRouteLocationIcon = (isStop?: boolean) => {
  if (isStop) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    return require(`@/assets/images/markers-with-car/stop.png`);
  }

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  return require(`@/assets/images/markers-with-car/vehicle-dot.svg`);
};
