<template>
  <div class="logo">
    <img alt="Logo" src="@/assets/images/logo.svg" />
  </div>
  <div class="message-container">
    <BaseError v-if="maintenanceMessage" :error="maintenanceMessage" :is-alert="true" />
  </div>
  <div class="container-sm">
    <slot />
  </div>
  <TheFooter class="footer" />
</template>

<style lang="scss" scoped>
.container-sm,
.message-container {
  width: 100%;
  max-width: 640px;
}
.container-sm {
  min-height: 320px;
  padding: 24px 56px 32px;
}
.logo {
  margin-top: 25px;
  margin-bottom: 10px;

  img {
    width: 138px;
  }
}
.footer {
  margin-top: 25px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import TheFooter from './TheFooter.vue';
import BaseError from '@/components/base/BaseError.vue';

export default defineComponent({
  name: 'AppLayoutAuth',
  components: {
    BaseError,
    TheFooter,
  },
  data() {
    return {
      maintenanceMessage: `The DryLINK server will undergo maintenance on Saturday Nov 16th to Sunday Nov17th. During this period, our services will be temporarily unavailable.
 We apologize for any inconvenience this may cause and appreciate your understanding and patience.`
    }
  },
});
</script>
