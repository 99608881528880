import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../assets/images/background/settings.svg'


const _hoisted_1 = { class: "settings-link-block" }
const _hoisted_2 = {
  key: 0,
  class: "avatar"
}
const _hoisted_3 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "link settings-link",
      to: { name: 'Settings', params: { tab: 'my-settings' } }
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "setting-icon"
        }, null, -1),
        _createTextVNode(" Settings ")
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/account",
      class: "settings-link"
    }, {
      default: _withCtx(() => [
        (_ctx.initials)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.initials), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.fullName), 1)
      ]),
      _: 1
    }),
    _createElementVNode("a", {
      href: "##",
      class: "link settings-link",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)), ["prevent"]))
    }, "Sign Out")
  ]))
}