export enum InventoryLocationType {
  FIXED = 'Fixed',
  WAREHOUSE = 'Warehouse',
  SERVICE = 'Service',
  SERVICE_CENTER = 'Service Center',
  JOB = 'Job',
  MOBILE = 'Mobile',
  GATEWAY = 'Gateway',
}

export interface InventoryLocation {
  id: number;
  invLocationId?: number;
  locationType?: InventoryLocationType;
  name: string;
  lastReadDate?: Date;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phoneName?: string;
  latitude?: number;
  longitude?: number;
  lastReadSecs?: number;
  gatewayType?: InventoryGatewayType;
  gatewayUuid?: string;
  fixedLocationId?: number;
  fixedLocationName?: string;
  isGpsCurrent?: boolean;
  numGaps?: number;
  status?: string;
}

export interface VehicleLiteLocation {
  gatewayUuid: string;
  gatewayName: string;
  id?: number;
}

export type InventoryLocations = InventoryLocation[];

export interface Coordinates {
  latitude: number;
  longitude: number;
  name: string;
}

export enum InventoryGatewayType {
  STAND_ALONE = 'Stand-alone',
  VEHICLE = 'Vehicle',
  WAREHOUSE = 'Warehouse',
}
