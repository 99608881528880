import type { Library } from '@googlemaps/js-api-loader';

export const MAP_API_LIBRARIES: Library[] = ['core', 'marker', 'maps', 'places'];

export const MAP_CONFIG = {
  center: {
    lat: 41.850033,
    lng: -87.6500523,
  },
  zoom: 4,
  maxZoom: 20,
  maxBoundsZoom: 18,
  minZoom: 3,
  streetViewControl: true,
  mapTypeControl: true,
  zoomControl: true,
  mapTypeId: 'satellite',
  // Map id is required for advanced markers.
  mapId: 'DEMO_MAP_ID',
};

export const getMapPinElementStyles = (isSmall?: boolean) => ({
  background: '#000000',
  borderColor: '#000000',
  scale: isSmall ? 1 : 1.55,
});
export const MARKER_WIDTH = 32;
export const SMALL_MARKER_WIDTH = 20;
export const getMapIconStyles = (isSmall?: boolean) => `
  border: 2px solid #000000;
  border-radius: 100%;
  width: ${isSmall ? SMALL_MARKER_WIDTH : MARKER_WIDTH}px;
  box-sizing: border-box;
`;

export const getStopIconStyles = () => `
  width: 24px;
`;

export const MAP_COORDINATE_FIXED_LENGTH = 3;

export const MAP_DASH_ICON = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 3,
};

export const MAP_REPEATED_DASH_ICON = {
  icon: MAP_DASH_ICON,
  offset: '0',
  repeat: '15px',
};

export const MAP_ARROW_ICON = {
  path: 'M -3,0.4 0,3.5 3,0.4',
  strokeLinecap: 'round',
  strokeOpacity: 1,
  scale: 3,
};

export const MAP_ARROW_ICON_WITH_OFFSET = {
  icon: MAP_ARROW_ICON,
  offset: '30%',
};
