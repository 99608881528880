import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_router_link, {
      class: _normalizeClass(["nav-link", { 'nav-link--active': _ctx.$route.path.startsWith(_ctx.JOBS_LINK) }]),
      to: _ctx.JOBS_LINK
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Jobs (" + _toDisplayString(_ctx.jobCount) + ") ", 1)
      ]),
      _: 1
    }, 8, ["class", "to"]),
    _createVNode(_component_router_link, {
      class: _normalizeClass(["nav-link", { 'nav-link--active': _ctx.$route.path.startsWith(_ctx.EQUIPMENT_LINK) }]),
      to: _ctx.EQUIPMENT_LINK
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Equipment (" + _toDisplayString(_ctx.equipCount) + ") ", 1)
      ]),
      _: 1
    }, 8, ["class", "to"]),
    (_ctx.isAssetsEnabled)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 0,
          class: _normalizeClass(["nav-link nav-submenu", {
        'nav-link--enabled': _ctx.isSubNavActive('asset'),
        'nav-link--active': _ctx.$route.path.startsWith(_ctx.ASSETS_LINK) || _ctx.$route.path.startsWith(_ctx.VEHICLES_LINK),
      }]),
          label: "ASSET MANAGER",
          "icon-right": "keyboard_arrow_up"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_menu, {
              class: "submenu-menu",
              onBeforeShow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enableSubNav('asset'))),
              onBeforeHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.disableSubNav('asset'))),
              "auto-close": "",
              fit: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item, { clickable: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              class: "nav-link",
                              to: _ctx.ASSETS_LINK
                            }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode("Tagged Assets")
                              ])),
                              _: 1
                            }, 8, ["to"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_item, { clickable: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              class: "nav-link",
                              to: _ctx.VEHICLES_LINK
                            }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode("Vehicle History")
                              ])),
                              _: 1
                            }, 8, ["to"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          class: _normalizeClass(["nav-link", { 'nav-link--active': _ctx.$route.fullPath === _ctx.ASSETS_LINK }]),
          to: _ctx.ASSETS_LINK
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" Asset Manager ")
          ])),
          _: 1
        }, 8, ["class", "to"])),
    _createVNode(_component_q_btn, {
      class: _normalizeClass(["nav-link nav-submenu", {
        'nav-link--enabled': _ctx.isSubNavActive(_ctx.MULTI_COMPANY_ASSETS_LINK),
        'nav-link--active': _ctx.$route.path.startsWith(_ctx.MULTI_COMPANY_ASSETS_LINK),
      }]),
      label: "ENTERPRISE",
      "icon-right": "keyboard_arrow_up"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_menu, {
          class: "submenu-menu",
          onBeforeShow: _cache[2] || (_cache[2] = ($event: any) => (_ctx.enableSubNav(_ctx.MULTI_COMPANY_ASSETS_LINK))),
          onBeforeHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.disableSubNav(_ctx.MULTI_COMPANY_ASSETS_LINK))),
          "auto-close": "",
          fit: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item, { clickable: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          class: "nav-link",
                          to: _ctx.MULTI_COMPANY_ASSETS_LINK
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("Available")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}