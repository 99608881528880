export enum EquipmentLocationType {
  FIXED = 'Fixed',
  WAREHOUSE = 'Warehouse',
  SERVICE = 'Service',
  SERVICE_CENTER = 'Service Center',
  JOB = 'Job',
  STANDALONE = 'Standalone',
  PHONE = 'Phone',
  VEHICLE = 'Vehicle',
}

export interface EquipmentLocation {
  id: number;
  latitude: number;
  longitude: number;
  type?: EquipmentLocationType;
}

export enum VehicleLocationType {
  JOB = 'Job',
  FIXED = 'Fixed',
  WAREHOUSE = 'Warehouse',
  SERVICE = 'Service',
  SERVICE_CENTER = 'Service Center',
  NONE = 'None',
}

export enum VehicleFixedLocationType {
  JOB = 'Job',
  FIXED = 'Fixed',
  WAREHOUSE = 'Warehouse',
  SERVICE = 'Service',
  SERVICE_CENTER = 'Service Center',
  NONE = 'None',
}
