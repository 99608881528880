import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo.svg'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = { class: "settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TheNav = _resolveComponent("TheNav")!
  const _component_TheSettingsLinks = _resolveComponent("TheSettingsLinks")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/jobs" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            class: "logo",
            alt: "Logo",
            src: _imports_0
          }, null, -1)
        ])),
        _: 1
      }),
      _createVNode(_component_TheNav, {
        "job-count": _ctx.data?.jobCount,
        "equip-count": _ctx.data?.equipCount
      }, null, 8, ["job-count", "equip-count"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TheSettingsLinks, {
        "first-name": _ctx.data?.firstName,
        "last-name": _ctx.data?.lastName
      }, null, 8, ["first-name", "last-name"])
    ])
  ]))
}