import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_BaseError = _resolveComponent("BaseError")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheHeader),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.maintenanceMessage)
        ? (_openBlock(), _createBlock(_component_BaseError, {
            key: 0,
            error: _ctx.maintenanceMessage,
            "is-alert": true
          }, null, 8, ["error"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["container", _ctx.containerClass])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createVNode(_component_TheFooter, { class: "footer" })
  ], 64))
}